/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid, FormControlLabel, Checkbox, TextField, Divider, Popper, PopperProps, Tooltip, Typography, IconButton } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { mainTheme } from '../../Theme';
import { Form, Field, FormSpy, FieldInputProps } from 'react-final-form';
import { AnyObject, FormApi } from 'final-form';
// import { ActionTypeSelector } from '../selectors/ActionType';
// import { OutcomeSelector } from '../selectors/OutcomeSelector';
import { ReasonSelector } from '../selectors/ReasonSelector';
import { client } from '../..';
import { showNotification } from '../../App';
import {
    FollowUpEnquiryMutation,
    EnquiryDetailsQueryParams,
    retrieveEnquiryDetailsData,
    CreateMeetingBookingTaskMutation,
    fetchAppUserData,
    AppUserData,
    AppUser,
    EnquiryDefaultConfig,
    retrieveEnquiryDefaultSettings,
    EnquiryDefaultSettings,
} from '../EnquirySummaryRepository';
import moment from 'moment';
import { RvLoader } from '../../components/Loader';
import { EnquiryDetail, EnquiryData } from '../models/Enquiry';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { ActionTypeRadio } from '../radio/ActionTypeRadio';
import { OutcomeRadio } from '../radio/OutcomeRadio';
import { TextFieldWithMention } from '../../components/TextFieldWithMention';
import { MentionItem } from 'react-mentions';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { CurrencyInput } from '../../components/CurrencyInput';
import { CurrencySymbolData, retrieveCurrencySymbolData } from '../../ClientAndContactDetails/referrals/ReferralsDetailsRepository';
import { SendEmailDialog } from './SendEmailDialog';
import { EmailTemplateTypeEnum } from '../../emailTemplatesConfiguration/EmailTemplateRespository';
import { EmailTemplateSelector } from '../selectors/EmailTemplateSelector';
import { addBusinessDays } from '../drawers/EnquiryFormDrawer';
import { ReminderSelector } from '../../components/ReminderSelector';
import { DocumentFile } from '../../types/DocumentFile';
import { ReferredToSelector } from '../selectors/ReferredToSelector';
import { IndividualNameSummary } from '../selectors/IndividualSelector';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { IDownShiftItem } from 'mui-downshift';
import { OfficeLocationSelector } from '../selectors/OfficeLocationSelector';
import { TimezoneSelector } from '../../lookupConfiguration/selectors/TimezoneSelector';
import { EntityTypeEnum, UserStaffAndTeamAutoCompleteSelector } from '../selectors/UserStaffAndTeamAutoCompleteSelector';
import { useRegionSettings } from '../../hooks/useRegionSettings';
import { BookMeetingEmailDialog } from './BookMeetingEmailDialog';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // width: '100%',
            // show autocomplete when overlapping the dialog container
            // '& .MuiDialog-container > div': {
            //     overflowY: 'visible'
            // },
            // '& .MuiDialogContent-root': {
            //     overflowY: 'inherit'
            // }
        },
        textField: {
            width: '100%',
            flex: 1,
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
            '& .MuiTypography-root': {
                display: 'flex',
                margin: '0 -10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .col': {
                    padding: '0 10px',
                    fontSize: '12px',
                    color: '#000',
                },
            },
        },
        hideDate: {
            display: 'none',
        },
        startTimeWrapper: {
            display: 'flex',
            flex: 1,
            alignItems: 'center',
        },
        formControlLabel: {
            marginLeft: 0,
            marginRight: 0,
        },
        textFieldWrapper: {
            flex: 1,
        },
        staffWrapper: {
            fontSize: 16,
            color: mainTheme.TemplateColor.Primary,
        },
        divider: {
            width: '100%',
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
        },
        customFieldGrid: {
            // paddingTop: '0px !important',
        },
        toggleFieldWrapepr: {
            display: 'flex',
            alignItems: 'flex-end',
            flex: 1,
            '& .field': {
                flex: 1,
            },
            '& .switcher': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
    }),
);

export interface FollowUp {
    guid?: string;
    followUpAction: string | null;
    followUpActionSystemName: string | null;
    followUpSystemAction: string | null;
    followUpDate: Date | null;
    followUpNotes: string;
    hasFollowUpNotesMentions: boolean;
    outcome: string | null;
    outcomeDate: Date | null;
    outcomeNotes: string | null;
    reason: string | null;
    reasonGuidID: string | null;
    isFormChanged: boolean;
    staff: IAutoCompleteItem | null;
    startTime: Date | null;
    endTime: Date | null;
    isAllDay: boolean;
    officeLocation: IAutoCompleteItem | null;
    feeApplicable: number | null;
    isSendConfirmation: boolean;
    meetingNotes: string | null;
    timezone: IAutoCompleteItem | null;

    emailContent: string | null;
    emailSubject: string | null;
    fromEmailAddress: string | null;
    toEmailAddress: string | null;
    ccEmailAddress: string | null;

    emailTemplate: IAutoCompleteItem | null;

    followUpDueReminder: IAutoCompleteItem | null;

    updateFollowUpDueForInternalNote: boolean;
    outcomeDateChanged: boolean;
    files: DocumentFile[];

    isShowNotProceedingReferrer: boolean;
    isNotProceedingReferrerSwitched: boolean;
    referredToFirmSelector: IAutoCompleteItem | null;
    referredToFirmGuid: string | null;
    referredToFirmName: string | null;
    notProceedingOutcomeNotes: string | null;
}

interface FollowUpDialogProps {
    guid?: string;
    onDialogClose?: () => void;
    firstName: string;
    lastName: string;
    email: string;
    canCreateMatter: boolean;
    followUpAction?: string;
    followUpActionSystemName?: string;
    followUpSystemAction?: string;
}

interface FollowUpDialogState {
    isFormChanged: boolean;
    showDiscard: boolean;
    currency: string;
    isSendEmailDialogOpen: boolean;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
}

// tslint:disable-next-line: no-any
export const FollowUpDialog: React.FunctionComponent<FollowUpDialogProps> = (props) => {
    const classes = useStyles();
    // tslint:disable-next-line: no-any
    // const { isFollowUpOpen, onFollowUpClose }: any = useContext(EnquirySummaryContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const regionSettings = useRegionSettings();

    const [enquiryDetails, setEnquiryDetails] = useState<EnquiryDetail>({
        guid: props.guid,
        createdDate: moment().toDate(),
        createdByName: '',
        lastUpdated: moment().toDate(),
        isClosed: false,
        isContactCreated: false,
        isMatterCreated: false,
        firstName: '',
        lastName: '',
        email: '',
        gender: 'Unknown',
        initialEnquiryDate: moment().toDate(),
        phone: '',
        mobile: '',
        sourceGuidID: null,
        source: '',
        isSourceReferrer: false,
        officeGuidID: null,
        office: '',
        sourceNotes: '',

        organisation: '',
        positionAtOrganisation: '',

        areaOfLaw: '',
        practiceAreaGuidID: null,
        natureOfEnquiry: '',

        internalNotes: null,
        files: [],

        assignedTo: null,
        assignedToName: null, // Display Name
        assignedToEmailAddress: null,
        assignedBy: null,
        assignedByName: null, // Display Name
        assignedByEmailAddress: null,
        dateAssigned: moment().toDate(),
        followUpDueDate: null,
        assignmentReminderOffset: null,

        followUpActionGuidID: null,
        followUpAction: null,
        followUpActionSystemName: null,
        followUpSystemAction: null,
        followUpDate: null,
        outcomeGuidID: null,
        outcome: null,
        outcomeDate: null,
        outcomeNotes: '',
        reasonGuidID: null,
        reason: null,
        followUpNotes: null,
        isKeyOpportunity: false,
        referrerGuid: null,
        referrerName: null,
        nameGuid: null,
        nameTitle: null,
        organisationGuid: null,

        estimatedFees: null,
        isEstimatedFeesIncludesTax: false,
        estimatedDisbursements: null,
        isEstimatedDisbursementsIncludesTax: false,
        enquiryQuality: null,
        likelihoodToProceed: null,
        clientFirstName: null,
        clientLastName: null,
        dOB: null,
        isMarketingConsent: false,
        isTermsAndConditionsApproved: false,
        isPrivacyStatementProvided: false,
        isConflictCheckPerformed: false,
        isNoConflict: false,
        isIdentityCheckPerformed: false,
        addressName: null,
        floor: null,
        number: null,
        street: null,
        clientAddressStreet2: null,
        suburb: null,
        state: null,
        postcode: null,
        country: null,
        pOBox: null,
        postalSuburb: null,
        postalState: null,
        postalPostCode: null,
        postalCountry: null,

        referredToFirmGuid: null,
        referredToFirmName: '',
        notProceedingOutcomeNotes: null,

        clientRelationshipToEnquirer: null,

        matterId: null,
        matterGuid: null,
        matterTitle: null,
        matterFileNumber: null,

        assignedToIsStaff: false,
        assignedToStaffGuid: null,
        assignedToStaffName: null,

        otherPartyFirstName: null,
        otherPartyLastName: null,
        otherPartyNotes: null,
        otherPartyEmail: null,
        otherPartyPhone: null,
        otherPartyMobile: null,
        otherPartyOrganisation: null,

        enquiryNotes: null,

        pOBoxType: null,

        integrationContactId: null,
        integrationClientId: null,
        integrationOtherPartyId: null,
        integrationMatterId: null,
        integrationMatterDocumentId: null,
        otherPartyDOB: null,
        otherPartyAddressName: null,
        otherPartyFloor: null,
        otherPartyNumber: null,
        otherPartyStreet: null,
        otherPartyStreet2: null,
        otherPartySuburb: null,
        otherPartyState: null,
        otherPartyPostcode: null,
        otherPartyCountry: null,

        otherPartyPOBoxType: null,
        otherPartyPOBox: null,
        otherPartyPostalSuburb: null,
        otherPartyPostalState: null,
        otherPartyPostalPostCode: null,
        otherPartyPostalCountry: null,
        otherPartyNameGuid: null,
        otherPartyOrganisationGuid: null,
        isRiskAssessed: null,
        riskLevel: null,
        riskLevelGuid: null,
        riskNotes: null,

        secondClientFirstName:                    null,
        secondClientLastName:                     null,
        secondClientRelationshipToEnquirer:       null,
        secondClientGender:                       null,
        secondClientSuburb:                       null,
        secondClientState:                        null,
        secondClientPostCode:                     null,
        secondClientIsMarketingConsent:           false,
        secondClientIsTermsAndConditionsApproved: false,
        secondClientIsPrivacyStatementProvided:   false,
        secondClientIsConflictCheckPerformed:     false,
        secondClientIsIdentityCheckPerformed:     false,
        secondClientIsNoConflict:                 false,
        secondClientCountry:                       null,
        secondClientAddressName:                   null,
        secondClientFloor:                         null,
        secondClientNumber:                        null,
        secondClientStreet1:                       null,
        secondClientStreet2:                       null,
        secondClientDOB:                           null,
        secondClientPOBoxType:                     null,
        secondClientPOBox:                         null,
        secondClientPostalSuburb:                  null,
        secondClientPostalState:                   null,
        secondClientPostalPostCode:                null,
        secondClientPostalCountry:                 null,
        secondClientGuid: null,

        responseText: null,

        eventAndCampaignGuid: null,
        eventAndCampaignName: null,
        eventAndCampaignNotes: null,
        eventAndCampaignNotesGuid: null,
        // hasFollowUpNotesMentions: false

        isNameFromDataSource: null,
        isOrganisationFromDataSource: null,
        isOtherSideFromDataSource: null,
        isSecondClientFromDataSource: null,

        clientTitle: null,
        otherPartyTitle: null,
        secondClientTitle: null,
        title: null,
        clientEmail: null,
        clientGuid: null,
        clientMobile: null,
        clientPhone: null,
        secondClientEmail: null,
        secondClientMobile: null,
        secondClientPhone: null,
    });

    const [appUser, setAppUser] = useState<AppUser>();

    const [state, setState] = useState<FollowUpDialogState>({
        showDiscard: false,
        isFormChanged: false,
        currency: '$',
        isSendEmailDialogOpen: false,
    });

    useEffect(() => {
        // - Ran only once
        // If we recieve a guid, then fetch EnquiryDetails
        if (props.guid) {
            setIsLoading(true);
            fetchCurrency();
            fetchEnquiryDefaultSettings();
            fetchAppUserOnEffect();
            fetchEnquiryDetails(props.guid);
        }
        // tslint:disable-next-line: align
    }, [props.guid]);

    const fetchCurrency = () => {
        retrieveCurrencySymbolData(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataCurrency(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onDataCurrency = (data: CurrencySymbolData) => {
        setState((prevState) => {
            return {
                ...prevState,
                currency: data.options.currencySymbol.symbol,
            };
        });
    };

    const fetchEnquiryDefaultSettings = () => {
        retrieveEnquiryDefaultSettings(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataEnquiryDefaultSettingsRetrieved(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onDataEnquiryDefaultSettingsRetrieved = (data: EnquiryDefaultSettings) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.enquiryDefaults && data.settings.systems.enquiryDefaults.config) {
            setState((prevState) => {
                return {
                    ...prevState,
                    enquiryDefaultConfig: data.settings.systems.enquiryDefaults.config,
                };
            });
        }
    };

    const fetchAppUserOnEffect = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => {
                handleAppUserFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
            },
        );
    };

    const handleAppUserFetch = (data?: AppUserData) => {
        if (data !== null && data?.appUser !== undefined && data.appUser.isAdministrator !== undefined) {
            setAppUser((prevAppUser) => {
                // Object.assign would also work
                return {
                    ...prevAppUser,
                    userId: data.appUser.userId,
                    name: data.appUser.name,
                    isAdministrator: data.appUser.isAdministrator,
                    userName: data.appUser.userName,
                };
            });
        }
    };

    const displayLoader = () => {
        if (isLoading) {
            return <RvLoader />;
        } else {
            return null;
        }
    };

    const fetchEnquiryDetails = (guid: string) => {
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            },
        );
    };

    const onDataRetrieved = (data: EnquiryData) => {
        setIsLoading(false);
        setEnquiryDetails(data.enquiry.detail);
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const requiredDateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        } else {
            return 'Required';
        }
    };

    // tslint:disable-next-line: no-any
    const onToggleBookMeetingEmailDialog = (form: FormApi<any>, isOpen: boolean) => {
        form.change('isBookMeetingEmailDialogOpen', isOpen);
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any,
    ) => {

        const isModifyAndSendPassRequirements = values.followUpSystemAction === 'BookMeeting' && values.staff 
                                                    && values.officeLocation && values.timezone && values.startTime && values.endTime
                                                    && UserFeature.HasAccess(UserFeatureEnum.hasBookMeetingEmailPreview);

        return (
            <div>
                {isModifyAndSendPassRequirements && (
                    <Button
                        type="button"
                        color="primary"
                        onClick={() => onToggleBookMeetingEmailDialog(form, true)}
                    >
                        Review & Send
                    </Button>
                )}
                <Button type="submit" color="primary" disabled={submitting || pristine || isSubmitting}>
                    {props.followUpAction ? 'Assign' : 'Save'}
                </Button>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </div>
        );
    };

    const onSubmit = (values: FollowUp) => {
        if (values.followUpActionSystemName === 'BookMeeting') {
            saveBookMetting(values);
        } else {
            saveFollowUp(values);
        }
    };

    // tslint:disable-next-line: no-any
    const saveBookMetting = (values: any) => {
        let startDate = values.startTime === null ? moment() : moment(values.startTime);
        let endDate = values.endTime === null ? moment() : moment(values.endTime);

        let startDateValue = values.startTime === null ? moment().toDate() : moment(values.startTime).toDate();
        let endDateValue = values.endTime === null ? moment().toDate() : moment(values.endTime).toDate();

        // If UI timezone is different from location's timezone, then add the offset difference to start and end time.
        let locationUtcOffset = values.officeLocation.baseUtcOffset;
        let uiUtcOffset = new Date().getTimezoneOffset() * -1; // comparing with UI's offset because the graphql will convert to UTC based on the UI timezone

        if (locationUtcOffset !== uiUtcOffset && locationUtcOffset > 0) {
            let difference = uiUtcOffset - locationUtcOffset;
            startDateValue = moment(startDate).add(difference, 'minutes').toDate();
            endDateValue = moment(endDate).add(difference, 'minutes').toDate();
        }

        var isAssignedToTeam = (values.staff ? values.staff.entityType : 0) === 6;

        // book meeting
        client
            .mutate({
                mutation: CreateMeetingBookingTaskMutation,
                variables: {
                    input: {
                        enquiryGuid: props.guid ? props.guid : null,
                        assignedTo: isAssignedToTeam ? (values.staff ? values.staff!.value : null) : appUser ? appUser!.userId : null,
                        followUpAction: values.followUpAction,
                        followUpActionSystemName: values.followUpActionSystemName,
                        startDate: startDateValue,
                        endDate: endDateValue,
                        isAllDay: values.isAllDay,
                        location: values.officeLocation ? values.officeLocation!.label : null,
                        feeApplicable: values.feeApplicable ? values.feeApplicable : null,
                        sendConfirmation: values.isSendConfirmation,
                        staff: values.staff ? values.staff!.value : null,
                        staffEntityType: values.staff ? values.staff.entityType : null,
                        meetingNotes: values.meetingNotes ? values.meetingNotes : null,
                    },
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        onFollowUpClosed();
                    } else {
                        showNotification('Failed to Save Follow up', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Follow up', reason, 'error');
            });
    };

    const saveFollowUp = (values: FollowUp) => {
        // Save the File
        client
            .mutate({
                mutation: FollowUpEnquiryMutation,
                variables: {
                    input: getFollowUpEnquiryInput(values),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');

                        onFollowUpClosed();
                    } else {
                        showNotification('Failed to Save Follow up', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Follow up', reason, 'error');
            });
    };

    const getFollowUpEnquiryInput = (values: FollowUp) => {
        return {
            enquiryGuid: props.guid,
            followUpAction: values.followUpAction,
            followUpActionSystemName: values.followUpActionSystemName,
            followUpDate: values.followUpDate === null ? null : moment(values.followUpDate).toDate(),
            outcome: values.outcome,
            outcomeDate:
                values.outcome === 'New Client' || values.outcome === 'New Matter' || values.outcome === 'Not Proceeding'
                    ? !values.followUpDate
                        ? null
                        : moment(values.followUpDate).toDate()
                    : !values.outcomeDate
                    ? null
                    : moment(values.outcomeDate).toDate(),
            outcomeNotes: values.outcomeNotes,
            reason: values.reason,
            reasonGuid: values.reasonGuidID,
            followUpNotes: values.followUpNotes,
            reminderOffset: values.outcome === 'More Follow Up' ? (values.followUpDueReminder ? values.followUpDueReminder.value : null) : null,
            updateFollowUpDueForInternalNote: values.updateFollowUpDueForInternalNote,

            referredToFirmName: values.referredToFirmName,
            referredToFirmGuid: values.referredToFirmGuid,
            notProceedingNotes: values.notProceedingOutcomeNotes,
        };
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<any>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isFormChanged', true);
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onFollowUpChange = (form: FormApi<FollowUp>, values: any, event: React.ChangeEvent<HTMLInputElement>, checked: boolean, value: string, systemAction: string) => {
        // tslint:disable-next-line: no-console
        // console.log(event);

        const followUpActionValue = event && event.currentTarget && event.currentTarget.value ? event.currentTarget.value : '';
        const followUpActionSystemName = value ? value : '';
        const followUpSystemAction = systemAction ? systemAction : '';

        form.batch(() => {
            form.change('followUpAction', followUpActionValue);
            form.change('followUpActionSystemName', followUpActionSystemName);
            form.change('followUpSystemAction', followUpSystemAction);

            if (systemAction && systemAction.toLowerCase() === 'internalnotes') {
                if (values.hasFollowUpNotesMentions === true && values.outcomeDateChanged === false) {
                    form.change('updateFollowUpDueForInternalNote', false);
                } else {
                    form.change('updateFollowUpDueForInternalNote', true);
                }
            }

            if (followUpSystemAction === 'CreateEmail') {
                setIsSubmitting(true);
            } else {
                setIsSubmitting(false);
                form.change('emailTemplate', null);
            }

            if (followUpActionValue && !values.outcome) {
                form.change('outcome', followUpActionValue ? 'More Follow Up' : '');
            }
        });
    };

    // tslint:disable-next-line: no-any
    const onOutcomeChange = (form: FormApi<FollowUp>, values: any, event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        // tslint:disable-next-line: no-console
        console.log(event);

        const outcome = event && event.currentTarget && event.currentTarget.value ? event.currentTarget.value : '';
        form.batch(() => {
            form.change('outcome', outcome);

            if (outcome !== 'Not Proceeding') {
                form.change('reason', null);
                form.change('reasonGuidID', null);
                form.change('isShowNotProceedingReferrer', false);
            }
        });

        // if (outcome && !values.outcomeDate) {
        //     form.change('outcomeDate', null);
        // }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true,
                };
            });
        } else {
            onFollowUpClosed();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            onFollowUpClosed();
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false,
                };
            });
        }
    };

    const onFollowUpClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    // tslint:disable-next-line: no-any max-line-length
    const onDateTimePickerChange = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>) => (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        let defaultMeetingMinutes = 60;

        if (state.enquiryDefaultConfig) {
            var defaultMeetingLength = state.enquiryDefaultConfig.defaultMeetingLength;
            var meetingLengthSplit = defaultMeetingLength.split(':'); // split it at the colons
            defaultMeetingMinutes = +meetingLengthSplit[0] * 60 + +meetingLengthSplit[1];
        }

        input.onChange(date);
        formApi.batch(() => {
            formApi.change('endTime', moment(date).add(defaultMeetingMinutes, 'minute').toDate());
        });
    };

    // tslint:disable-next-line: no-any
    const onOutcomeDateChange = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>) => (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        input.onChange(date);

        formApi.batch(() => {
            formApi.change('updateFollowUpDueForInternalNote', true);
            formApi.change('outcomeDateChanged', true);
        });
    };

    // tslint:disable-next-line: max-line-length no-any
    const onChangeAllDay = (input: FieldInputProps<any, HTMLElement>, formApi: FormApi<any>, values: AnyObject) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            input.onChange(checked);
            if (checked) {
                const startDate = moment(values.startTime).set({ hours: 9, minutes: 0, seconds: 0, milliseconds: 0 }).toDate();
                const endDate = moment(values.endTime).set({ hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }).toDate();
                formApi.batch(() => {
                    formApi.change('startTime', startDate);
                    formApi.change('endTime', endDate);
                });
            }
        };

    // tslint:disable-next-line: no-any
    const onDropdownChange = (selection: IAutoCompleteItem, name: string, input: FieldInputProps<any, HTMLElement>, form: FormApi<any>, valueGuid: string) => {
        input.onChange(selection.label.length > 0 ? selection.label : null);

        if (name === 'reason') {
            form.change('reasonGuidID', valueGuid.length > 0 ? valueGuid : null);
        } else if (name === 'notProceedingReason') {
            form.change('notProceedingReasonGuid', valueGuid.length > 0 ? valueGuid : null);
        }

        if (selection.secondaryValue) {
            form.change('isShowNotProceedingReferrer', selection.secondaryValue);
        } else {
            form.change('isShowNotProceedingReferrer', false);

            form.change('referredToFirmSelector', null);
            form.change('referredToFirmName', '');
            form.change('referredToFirmGuid', null);
            form.change('isNotProceedingReferrerSwitched', false);
        }
    };
    // tslint:disable-next-line: no-any max-line-length
    const onDropDownSelect = (form: FormApi<FollowUp>, values: AnyObject, input: FieldInputProps<any, HTMLElement>, selection: IndividualNameSummary | IDownShiftItem | any, name: string) => {
        if (name === 'referredToFirmSelector') {
            form.batch(() => {
                const referredToFirmSelectorValue: IAutoCompleteItem = {
                    label: selection && selection.label ? selection.label : '',
                    value: selection && selection.value ? selection.value : null,
                };

                form.change('referredToFirmSelector', referredToFirmSelectorValue);
                form.change('referredToFirmName', selection && selection.label ? selection.label : '');
                form.change('referredToFirmGuid', selection && selection.value ? selection.value : null);
            });
        }
    };

    const onSwitch = (form: FormApi<FollowUp>, values: AnyObject, name: string) => {
        if (name === 'referredToFirm') {
            form.batch(() => {
                form.change('isNotProceedingReferrerSwitched', !values.isNotProceedingReferrerSwitched);
                // form.change('isShowNotProceedingReferrer', !values.isShowNotProceedingReferrer);
                form.change('referredToFirmSelector', null);
                form.change('referredToFirmGuid', null);
                form.change('referredToFirmName', '');
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onEmailTemplateChange = (form: FormApi<any>, selection: IAutoCompleteItem) => {
        form.change('emailTemplate', selection);

        if (selection && selection.value) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isSendEmailDialogOpen: true,
                };
            });
        }
    };

    const CustomPopper = function (popperProps: PopperProps) {
        return <Popper {...popperProps} placement="top" />;
    };

    // tslint:disable-next-line: no-any
    const onSendEmailDialogClose = (form: FormApi<any>) => {
        setState((prevState) => {
            return {
                ...prevState,
                isSendEmailDialogOpen: false,
            };
        });

        // Change back to default value
        form.batch(() => {
            form.change('emailTemplate', null);
            form.change('followUpAction', 'Emailed');
            form.change('followUpActionSystemName', 'Emailed');
            form.change('followUpSystemAction', null);
        });
    };

    const getReminderValue = () => {
        if (enquiryDetails && enquiryDetails.assignmentReminderOffset) {
            return { label: enquiryDetails.assignmentReminderOffset, value: enquiryDetails.assignmentReminderOffset };
        } else if (state.enquiryDefaultConfig && state.enquiryDefaultConfig.reminder) {
            return { label: state.enquiryDefaultConfig.reminder, value: state.enquiryDefaultConfig.reminder };
        } else {
            return { label: 'None', value: 'None' };
        }
    };

    const getOutcomeDateDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;

        if (state.enquiryDefaultConfig) {
            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;

            if (state.enquiryDefaultConfig.timeOfDay && state.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = state.enquiryDefaultConfig.timeOfDay ? state.enquiryDefaultConfig.timeOfDay.split(':') : null;

                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }

            if (state.enquiryDefaultConfig.isCalculatedDays) {
                let date = addBusinessDays(moment().toDate(), state.enquiryDefaultConfig.addDays ? state.enquiryDefaultConfig.addDays : 0);

                if (validTimeOfDay) {
                    outcomeDate = date.set('hour', hours).set('minute', minutes).toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (state.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = state.enquiryDefaultConfig.addHours ? state.enquiryDefaultConfig.addHours : 0;

                outcomeDate = moment().add(addhours, 'hour').toDate();
            }

            // Manual default time is set through getOutcomeDefaultTime()
        }

        return outcomeDate;
    };

    const getOutcomeDefaultTime = () => {
        let defaultTime = new Date().setHours(17, 0, 0, 0);

        let hours = 0;
        let minutes = 0;

        if (
            state.enquiryDefaultConfig &&
            state.enquiryDefaultConfig.isManualEntry &&
            state.enquiryDefaultConfig.timeOfDay &&
            state.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date'
        ) {
            var timeOfDaySplit = state.enquiryDefaultConfig.timeOfDay ? state.enquiryDefaultConfig.timeOfDay.split(':') : null;

            if (timeOfDaySplit) {
                hours = Number(timeOfDaySplit[0]);
                minutes = Number(timeOfDaySplit[1]);
            }

            defaultTime = new Date().setHours(hours, minutes, 0, 0);
        }

        return defaultTime;
    };

    // tslint:disable-next-line: no-any
    const onChangeSelection = (selection: any, input: FieldInputProps<FollowUp, any>, form: FormApi<FollowUp>, name: string) => {
        form.batch(() => {
            if (name === 'staff') {
                input.onChange(selection);
                if (selection) {
                    if (selection.hasOwnProperty('entityType') && selection.entityType === EntityTypeEnum.TEAM) {
                        form.change('isSendConfirmation', false);
                    }
                    if (selection.hasOwnProperty('entityType') && selection.entityType === EntityTypeEnum.STAFF) {
                        form.change('isSendConfirmation', true);
                    }
                } else {
                    form.change('isSendConfirmation', true);
                }
            }

            if (name === 'officeLocation') {
                input.onChange(selection);

                if (selection && selection.hasOwnProperty('timezone') && selection.hasOwnProperty('timezoneDescription') && selection.timezone && selection.timezoneDescription) {
                    const timezoneSelector = {
                        label: selection.timezoneDescription,
                        value: selection.timezone,
                        secondaryValue: selection.baseUtcOffset,
                    };
                    form.change('timezone', timezoneSelector);
                } else {

                    const { timeZone, timeZoneDescription, baseUtcOffset } = regionSettings.data!.settings.systems.regionSettings.config;

                    const timezoneSelector = {
                        label: timeZoneDescription,
                        value: timeZone,
                        secondaryValue: baseUtcOffset,
                    };

                    form.change('timezone', timezoneSelector);
                }
            }
        });
    };

    return (
        <>
            <DialogBox
                title="Follow Up"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard ? state.showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root} fullWidth={true} maxWidth="md" scroll="paper">
                {isLoading || regionSettings.loading ? (
                    displayLoader()
                ) : (
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            followUpAction: props.followUpAction ? props.followUpAction : 'Emailed',
                            followUpActionSystemName: props.followUpActionSystemName ? props.followUpActionSystemName : 'Emailed',
                            followUpSystemAction: props.followUpSystemAction ? props.followUpSystemAction : '',
                            followUpDate: new Date(),
                            followUpNotes: '',
                            hasFollowUpNotesMentions: false,
                            outcome: 'More Follow Up',
                            outcomeDate: getOutcomeDateDefaultValue(),
                            outcomeNotes: '',
                            reason: '',
                            reasonGuid: null,
                            isFormChanged: false,
                            staff: null,
                            startTime: null,
                            endTime: null,
                            isAllDay: false,
                            officeLocation: null,
                            feeApplicable: null,
                            isSendConfirmation: true,
                            meetingNotes: null,
                            emailTemplate: null,
                            followUpDueReminder: getReminderValue(),
                            updateFollowUpDueForInternalNote: true,
                            outcomeDateChanged: false,
                            timezone: null,
                            isBookMeetingEmailDialogOpen: false
                        }}
                        initialValuesEqual={() => true}
                        // keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true, values: true }}
                        validate={(values) => {
                            let errors = {
                                followUpDate: '',
                                outcomeDate: '',
                                rason: '',
                                endTime: '',
                            };

                            if (values.followUpAction) {
                                let followUpDateError = requiredDateValidator(values.followUpDate);
                                errors.followUpDate = followUpDateError !== undefined ? followUpDateError : '';
                            }

                            if (!values.hasFollowUpNotesMentions && values.followUpDate && values.outcome === 'More Follow Up') {
                                let outcomeDateError = requiredDateValidator(values.followUpDate);
                                errors.outcomeDate = outcomeDateError !== undefined ? outcomeDateError : '';
                            }

                            if (values.outcome && values.outcome === 'Not Proceeding') {
                                errors.rason = values.reason ? '' : 'Reason is required';
                            }

                            if (errors.followUpDate === '' && errors.outcomeDate === '' && errors.rason === '') {
                                return undefined;
                            }

                            return errors;
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                {displayLoader()}
                                <FormSpy
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(props) => {
                                        onFormValueChanged(form, props);
                                    }}
                                />

                                <FormSpy subscription={{ values: true }}>
                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                    {({ values }) => (
                                        <>
                                            {values.isBookMeetingEmailDialogOpen && (
                                                <BookMeetingEmailDialog
                                                    enquiryGuid={props.guid ?? ''}
                                                    followUpAction={values.followUpAction}
                                                    followUpActionSystemName={values.followUpActionSystemName}
                                                    isSendConfirmation={values.isSendConfirmation}
                                                    fromAddress={appUser ? appUser.userName : null}
                                                    toAddresses={props.email}
                                                    onClose={() => onToggleBookMeetingEmailDialog(form, false)}
                                                    open={values.isBookMeetingEmailDialogOpen}
                                                    staff={values.staff}
                                                    officeLocation={values.officeLocation ? values.officeLocation.value : null}
                                                    timezone={values.timezone ? values.timezone.value : null}
                                                    startTime={values.startTime}
                                                    endTime={values.endTime}
                                                    isAllDay={values.isAllDay}
                                                    meetingNotes={values.meetingNotes}
                                                    feeApplicable={values.feeApplicable}
                                                />
                                            )}
                                        </>
                                    )}
                                </FormSpy>
                                <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
                                    <div>Follow Up</div>
                                    <div className={'col'}>
                                        {enquiryDetails.assignedTo
                                            ? 'Assigned to ' +
                                              enquiryDetails.assignedToName +
                                              (enquiryDetails.followUpDueDate && enquiryDetails.followUpDueDate !== null
                                                  ? ', due by ' + (enquiryDetails.followUpDueDate ? moment(enquiryDetails.followUpDueDate).format('DD MMM YYYY') : 'None')
                                                  : '')
                                            : ''}
                                    </div>
                                </DialogTitle>
                                <DialogContent dividers={true}>
                                    <Grid container={true} spacing={3}>
                                        {!!!props.followUpAction && (
                                            <Grid item={true} xs={12}>
                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <ActionTypeRadio
                                                            name="followUpAction"
                                                            value={values.followUpAction}
                                                            label="Follow Up Action"
                                                            onRadioButtonChange={(
                                                                event: React.ChangeEvent<HTMLInputElement>,
                                                                checked: boolean,
                                                                value: string,
                                                                systemAction: string,
                                                            ) => onFollowUpChange(form, values, event, checked, value, systemAction)}
                                                        />
                                                    )}
                                                </FormSpy>
                                            </Grid>
                                        )}
                                        <FormSpy subscription={{ values: true }}>
                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                            {({ values }) => {
                                                if (values.followUpActionSystemName === 'BookMeeting') {
                                                    return (
                                                        <>
                                                            {!!!props.followUpAction && <Divider className={classes.divider} />}
                                                            <Grid item={true} xs={12}>
                                                                <Grid container={true} spacing={1}>
                                                                    {!!(props.firstName && props.lastName && props.email) && (
                                                                        <Grid item={true} xs={12} md={12}>
                                                                            <div
                                                                                className={classes.staffWrapper}
                                                                            >
                                                                                {`Meeting booking for ${props.firstName} ${props.lastName} (${props.email})`}
                                                                            </div>
                                                                        </Grid>
                                                                    )}
                                                                    <Grid item={true} xs={12} md={6}>
                                                                        <Field
                                                                            name="staff"
                                                                            // tslint:disable-next-line: max-line-length
                                                                            validate={required}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <UserStaffAndTeamAutoCompleteSelector
                                                                                        {...input}
                                                                                        label="Staff"
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                            onChangeSelection(selection, input, form, name)
                                                                                        }
                                                                                        onBlur={input.onBlur}
                                                                                        error={meta.error && meta.touched}
                                                                                        disablePortal={false}
                                                                                        className={classes.textField}
                                                                                        helperText={
                                                                                            meta.error && meta.touched
                                                                                                ? 'Staff or Team is required'
                                                                                                : 'The person or team in the firm who the meeting is with'
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={6}>
                                                                        {!!(values.staff && values.staff.entityType === EntityTypeEnum.STAFF) && (
                                                                            <Field name="isSendConfirmation" subscription={{ touched: true, error: true, value: true }}>
                                                                                {({ input, meta }) => (
                                                                                    <FormControlLabel
                                                                                        className={classes.formControlLabel}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                {...input}
                                                                                                checked={input.value}
                                                                                                onChange={input.onChange}
                                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                            />
                                                                                        }
                                                                                        label="Send Confirmation"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        )}
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={7}>
                                                                        <Field
                                                                            name="officeLocation"
                                                                            validate={required}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <OfficeLocationSelector
                                                                                        {...input}
                                                                                        label="Location"
                                                                                        required={true}
                                                                                        value={input.value ? input.value : null}
                                                                                        className={classes.textField}
                                                                                        error={meta.error && meta.touched}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                            onChangeSelection(selection, input, form, name)
                                                                                        }
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                        //     input.onChange(selection)
                                                                                        // }
                                                                                        onBlur={input.onBlur}
                                                                                        helperText={meta.error && meta.touched ? 'Location is required' : undefined}
                                                                                        showAllOffice={true}
                                                                                        disablePortal={false}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={5}>
                                                                        <Field
                                                                            name="timezone"
                                                                            // validate={required}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <TimezoneSelector
                                                                                    {...input}
                                                                                    label="Invitee's Timezone"
                                                                                    onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                                                                    className={classes.textField}
                                                                                    disablePortal={false}
                                                                                    onBlur={input.onBlur}
                                                                                    error={meta.error && meta.touched}
                                                                                    helperText={meta.error && meta.touched ? 'Timezone is required' : undefined}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={6}>
                                                                        <Field name="startTime" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <KeyboardDateTimePicker
                                                                                        {...input}
                                                                                        className={classes.textField}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        onChange={onDateTimePickerChange(input, form)}
                                                                                        id="startTime"
                                                                                        name="startTime"
                                                                                        label="Invitee's Start *"
                                                                                        format="DD/MM/YYYY hh:mm a"
                                                                                        placeholder="dd/mm/yyyy hh:mm a"
                                                                                        animateYearScrolling={true}
                                                                                        autoOk={true}
                                                                                        variant={'inline'}
                                                                                        error={meta.error && meta.touched}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        helperText={meta.error && meta.touched ? 'Start time is required' : ''}
                                                                                        inputProps={{ autocomplete: 'off' }}
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        minDate={moment().toDate()}
                                                                                        initialFocusedDate={new Date().setHours(12, 0, 0, 0)}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // required={true}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={6}>
                                                                        <div className={classes.startTimeWrapper}>
                                                                            <Field name="endTime" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                                                                {({ input, meta }) => (
                                                                                    <div className={classes.textFieldWrapper}>
                                                                                        <KeyboardDateTimePicker
                                                                                            {...input}
                                                                                            className={classes.textField}
                                                                                            id="endTime"
                                                                                            name="endTime"
                                                                                            label="Invitee's End *"
                                                                                            format="DD/MM/YYYY hh:mm a"
                                                                                            placeholder="dd/mm/yyyy hh:mm a"
                                                                                            animateYearScrolling={true}
                                                                                            autoOk={true}
                                                                                            variant={'inline'}
                                                                                            error={meta.error && meta.touched}
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            helperText={meta.error && meta.touched ? 'End time is required' : ''}
                                                                                            inputProps={{ autocomplete: 'off' }}
                                                                                            InputLabelProps={{ shrink: true }}
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            minDate={moment(values.startTime).toDate()}
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            // initialFocusedDate={moment(values.startTime).add(1, 'hour').toDate()}
                                                                                            // required={true}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Field>
                                                                            <Field name="isAllDay" subscription={{ touched: true, error: true, value: true }}>
                                                                                {({ input, meta }) => (
                                                                                    <FormControlLabel
                                                                                        className={classes.formControlLabel}
                                                                                        control={
                                                                                            <Checkbox
                                                                                                {...input}
                                                                                                checked={input.value}
                                                                                                onChange={onChangeAllDay(input, form, values)}
                                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                            />
                                                                                        }
                                                                                        label="All day"
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={12}>
                                                                        <Field
                                                                            name="meetingNotes"
                                                                            // validate={required}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <TextField
                                                                                    {...input}
                                                                                    label="Meeting Notes"
                                                                                    type="text"
                                                                                    multiline={true}
                                                                                    // required={true}
                                                                                    className={classes.textField}
                                                                                    error={meta.error && meta.touched}
                                                                                    autoComplete="abcd"
                                                                                    helperText={meta.error && meta.touched ? 'Meeting notes is required' : ''}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <Grid item={true} xs={12} md={2}>
                                                                        <Field
                                                                            name="feeApplicable"
                                                                            label="Fee Applicable"
                                                                            // helperText={'Debtors amount to write off'}
                                                                            subscription={{ touched: true, error: true, value: true }}
                                                                            prefix={state.currency}
                                                                            className={classes.textField}
                                                                            component={CurrencyInput}
                                                                            // disabled={props.formAction === 'edit'}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    );
                                                } else if (values.followUpSystemAction === 'CreateEmail') {
                                                    return (
                                                        <>
                                                            <Divider className={classes.divider} />
                                                            <Grid item={true} xs={12}>
                                                                <Grid container={true} spacing={3}>
                                                                    <Grid item={true} xs={12} md={6}>
                                                                        <Field name="emailTemplate" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                                                            {({ input, meta }) => (
                                                                                <div>
                                                                                    <EmailTemplateSelector
                                                                                        {...input}
                                                                                        label="Email Template"
                                                                                        // required={true}
                                                                                        value={input.value ? input.value : null}
                                                                                        className={classes.textField}
                                                                                        error={meta.error && meta.touched}
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        onSelection={(selection: IAutoCompleteItem, name: string) =>
                                                                                            onEmailTemplateChange(form, selection)
                                                                                        }
                                                                                        onBlur={input.onBlur}
                                                                                        helperText={meta.error && meta.touched ? 'Email Template is required' : undefined}
                                                                                        PopperComponent={CustomPopper}
                                                                                        areaOfLaw={enquiryDetails.areaOfLaw ? enquiryDetails.areaOfLaw : ''}
                                                                                        emailType={EmailTemplateTypeEnum.EMAIL_RESPONSE}
                                                                                        disablePortal={false}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        </Field>
                                                                    </Grid>
                                                                    <>
                                                                        {values.followUpSystemAction === 'CreateEmail' && values.emailTemplate && (
                                                                            <SendEmailDialog
                                                                                enquiryGuid={props.guid ? props.guid : ''}
                                                                                emailTemplateGuid={values.emailTemplate.value}
                                                                                emailTemplateName={values.emailTemplate.label}
                                                                                emailType={EmailTemplateTypeEnum.EMAIL_RESPONSE}
                                                                                followUpAction={values.followUpAction}
                                                                                followUpActionSystemName={values.followUpActionSystemName}
                                                                                followUpDate={values.followUpDate}
                                                                                fromEmailAddress={appUser ? appUser.userName : ''}
                                                                                toEmailAddress={props.email}
                                                                                onDialogClose={() => onSendEmailDialogClose(form)}
                                                                                isSendEmailDialogOpen={state.isSendEmailDialogOpen}
                                                                                enquiryDefaultConfig={state.enquiryDefaultConfig}
                                                                                canCreateMatter={props.canCreateMatter}
                                                                            />
                                                                        )}
                                                                    </>
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <Grid item={true} xs={12}>
                                                                <Grid container={true} spacing={3}>
                                                                    <Grid item={true} xs={12} md={4}>
                                                                        {/* Without FormSpy, rules will not update in Realtime */}
                                                                        <FormSpy subscription={{ values: true }}>
                                                                            {/* tslint:disable-next-line: no-shadowed-variable */}
                                                                            {({ values }) => (
                                                                                <div>
                                                                                    <Field
                                                                                        name="followUpDate"
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // validate={values.followUpAction !== null ? requiredDateValidator : undefined}
                                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                                    >
                                                                                        {({ input, meta }) => (
                                                                                            <div>
                                                                                                <KeyboardDateTimePicker
                                                                                                    {...input}
                                                                                                    className={classes.textField}
                                                                                                    id="followUpDate"
                                                                                                    name="followUpDate"
                                                                                                    label="Date"
                                                                                                    format="DD/MM/YYYY hh:mm a"
                                                                                                    placeholder="dd/mm/yyyy hh:mm a"
                                                                                                    animateYearScrolling={true}
                                                                                                    autoOk={true}
                                                                                                    variant={'inline'}
                                                                                                    error={meta.error && meta.touched}
                                                                                                    // tslint:disable-next-line: max-line-length
                                                                                                    helperText={meta.error && meta.touched ? 'Follow Up Date is required' : ''}
                                                                                                    inputProps={{ autocomplete: 'off' }}
                                                                                                    InputLabelProps={{ shrink: true }}
                                                                                                    // tslint:disable-next-line: max-line-length
                                                                                                    initialFocusedDate={new Date().setHours(17, 0, 0, 0)}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            )}
                                                                        </FormSpy>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field name="followUpNotes" subscription={{ touched: true, error: true, value: true }}>
                                                                    {({ input, meta }) => (
                                                                        <TextFieldWithMention
                                                                            {...input}
                                                                            className={classes.textField}
                                                                            label="Follow Up Notes"
                                                                            markup="@[__display__](user:__id__)"
                                                                            // tslint:disable-next-line: max-line-length
                                                                            onChange={(
                                                                                event: { target: { value: string } },
                                                                                newValue: string,
                                                                                newPlainTextValue: string,
                                                                                mentions: MentionItem[],
                                                                            ) => {
                                                                                input.onChange(newValue);

                                                                                if (mentions && mentions.length > 0) {
                                                                                    form.batch(() => {
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // If we have mentions, change the Follow Up Action to - Internal Note
                                                                                        // And mark Follow Up Due as not mandatory
                                                                                        form.change('followUpAction', 'Internal Note');
                                                                                        form.change('followUpActionSystemName', 'InternalNote');

                                                                                        form.change('hasFollowUpNotesMentions', true);
                                                                                        form.change('updateFollowUpDueForInternalNote', false);
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                    });
                                                                                } else {
                                                                                    form.change('hasFollowUpNotesMentions', false);
                                                                                }
                                                                            }}
                                                                            onlyUsedByEnquiries={true}
                                                                            isLoadData={true}
                                                                        />
                                                                        // <TextField
                                                                        //     {...input}
                                                                        //     label="Follow Up Notes"
                                                                        //     type="text"
                                                                        //     multiline={true}
                                                                        //     className={classes.textField}
                                                                        //     autoComplete="abcd"
                                                                        // />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <FormSpy subscription={{ values: true }}>
                                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                                    {({ values }) => (
                                                                        <OutcomeRadio
                                                                            name="outcome"
                                                                            label="Outcome"
                                                                            hasNameGuid={enquiryDetails.nameGuid ? true : false}
                                                                            canCreateMatter={props.canCreateMatter}
                                                                            // tslint:disable-next-line: max-line-length
                                                                            onRadioButtonChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                                                                onOutcomeChange(form, values, event, checked)
                                                                            }
                                                                        />
                                                                    )}
                                                                </FormSpy>
                                                            </Grid>

                                                            {/* Without FormSpy, rules will not update in Realtime */}
                                                            <FormSpy subscription={{ values: true }}>
                                                                {/* tslint:disable-next-line: no-shadowed-variable max-line-length*/}
                                                                {({ values }) => values.outcome === 'More Follow Up' && (
                                                                    <Grid item={true} xs={12}>
                                                                        <Grid container={true} spacing={3}>
                                                                            <Grid item={true} xs={6} md={4}>
                                                                                <div>
                                                                                    <Field
                                                                                        name="outcomeDate"
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // validate={values.followUpDate !== null && values.outcome === 'More Follow Up' ? requiredDateValidator : undefined}
                                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                                    >
                                                                                        {({ input, meta }) => (
                                                                                            <KeyboardDateTimePicker
                                                                                                {...input}
                                                                                                className={classes.textField}
                                                                                                id="outcomeDate"
                                                                                                name="outcomeDate"
                                                                                                label="Follow Up Due"
                                                                                                format="DD/MM/YYYY hh:mm a"
                                                                                                placeholder="dd/mm/yyyy hh:mm a"
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                required={
                                                                                                    values.hasFollowUpNotesMentions
                                                                                                        ? false
                                                                                                        : values.followUpDate !== null && values.outcome === 'More Follow Up'
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                onChange={onOutcomeDateChange(input, form)}
                                                                                                animateYearScrolling={true}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                autoOk={true}
                                                                                                allowKeyboardControl={true}
                                                                                                variant={'inline'}
                                                                                                error={meta.touched && meta.error}
                                                                                                helperText={meta.touched && meta.error ? 'Follow Up Due Date is Required' : ''}
                                                                                                inputProps={{ autocomplete: 'off' }}
                                                                                                InputLabelProps={{ shrink: true }}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                initialFocusedDate={getOutcomeDefaultTime()}
                                                                                            />
                                                                                            // tslint:disable-next-line:max-line-length
                                                                                            // <div className={values.outcome === 'More Follow Up' ? '' : classes.hideDate} >

                                                                                            // </div>
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </Grid>
                                                                            <Grid item={true} xs={6} md={4} alignItems="flex-end">
                                                                                <Field name="followUpDueReminder" subscription={{ touched: true, error: true, value: true }}>
                                                                                    {({ input, meta }) => (
                                                                                        <div className={classes.selector}>
                                                                                            <ReminderSelector
                                                                                                {...input}
                                                                                                label="Reminder"
                                                                                                className={classes.textField}
                                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                                    input.onChange(selection);
                                                                                                }}
                                                                                                error={meta.error && meta.touched}
                                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                                showIcon={true}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid item={true} xs={6} md={4}>
                                                                                <div>
                                                                                    <Field
                                                                                        name="outcomeNotes"
                                                                                        // tslint:disable-next-line: max-line-length
                                                                                        // validate={values.followUpDate !== null && values.outcome === 'More Follow Up' ? requiredDateValidator : undefined}
                                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                                    >
                                                                                        {({ input, meta }) => (
                                                                                            <TextField
                                                                                                {...input}
                                                                                                label="Notes"
                                                                                                type="text"
                                                                                                // required={true}
                                                                                                className={classes.textField}
                                                                                                error={meta.error && meta.touched}
                                                                                                autoComplete="abcd"
                                                                                                // helperText={
                                                                                                //     meta.error &&
                                                                                                //     meta.touched
                                                                                                //         ? 'Notes is required'
                                                                                                //         : ''
                                                                                                // }
                                                                                            />
                                                                                        )}
                                                                                    </Field>
                                                                                </div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            </FormSpy>
                                                            <FormSpy subscription={{ values: true }}>
                                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                                {({ values }) => values.outcome === 'Not Proceeding' && (
                                                                    <>
                                                                        <Grid item={true} xs={4}>
                                                                            <Field
                                                                                name="reason"
                                                                                // tslint:disable-next-line: max-line-length
                                                                                // validate={form.getFieldState('outcome')?.value === 'Not Proceeding' ? required : undefined}
                                                                                subscription={{ touched: true, error: true, value: true }}
                                                                            >
                                                                                {({ input, meta }) => (
                                                                                    <div>
                                                                                        <ReasonSelector
                                                                                            {...input}
                                                                                            label="Not Proceeding Reason"
                                                                                            name="reason"
                                                                                            className={classes.textField}
                                                                                            error={meta.error && meta.touched}
                                                                                            helperText={meta.error && meta.touched ? 'Reason is required' : ''}
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            onSelection={(selection: IAutoCompleteItem, name: string, reasonGuid: string) => {
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                onDropdownChange(selection, name, input, form, reasonGuid);
                                                                                            }}
                                                                                            required={form.getFieldState('outcome')?.value === 'Not Proceeding' ? true : false}
                                                                                            disabled={form.getFieldState('outcome')?.value !== 'Not Proceeding' ? true : false}
                                                                                            disablePortal={false}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                        {values.isShowNotProceedingReferrer && (
                                                                            <Grid item={true} xs={4} className={classes.customFieldGrid}>
                                                                                <div className={classes.toggleFieldWrapepr}>
                                                                                    <div className="field">
                                                                                        {values.isNotProceedingReferrerSwitched ? (
                                                                                            <Field
                                                                                                name="referredToFirmName"
                                                                                                // validate={required}
                                                                                                subscription={{ touched: true, error: true, value: true }}
                                                                                            >
                                                                                                {({ input, meta }) => (
                                                                                                    <TextField
                                                                                                        {...input}
                                                                                                        label="Referred To"
                                                                                                        type="text"
                                                                                                        // required={!isTabActivity()}
                                                                                                        className={classes.textField}
                                                                                                        error={meta.error && meta.touched}
                                                                                                        autoComplete="abcd"
                                                                                                        helperText={meta.error && meta.touched ? 'Referred To is required' : ''}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        ) : (
                                                                                            <Field
                                                                                                name="referredToFirmSelector"
                                                                                                subscription={{ touched: true, error: true, value: true }}
                                                                                            >
                                                                                                {({ input, meta }) => (
                                                                                                    <ReferredToSelector
                                                                                                        {...input}
                                                                                                        label="Referred To"
                                                                                                        value={values.referredToFirmSelector}
                                                                                                        error={meta.error && meta.touched}
                                                                                                        helperText={meta.error && meta.touched ? 'Referred To is required' : ''}
                                                                                                        onSelection={(selection: IndividualNameSummary, name: string) => {
                                                                                                            input.onChange(selection);
                                                                                                            onDropDownSelect(form, values, input, selection, name);
                                                                                                        }}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="switcher">
                                                                                        <Tooltip
                                                                                            // placement="left-start"
                                                                                            title={
                                                                                                values.isNotProceedingReferrerSwitched ? (
                                                                                                    <Typography color="inherit">
                                                                                                        Switch to select an existing client & contact
                                                                                                    </Typography>
                                                                                                ) : (
                                                                                                    // tslint:disable-next-line: max-line-length
                                                                                                    <Typography color="inherit">
                                                                                                        Switch to enter the referred to name
                                                                                                    </Typography>
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <IconButton
                                                                                                aria-label="delete"
                                                                                                // className={classes.margin}
                                                                                                onClick={() => {
                                                                                                    // Another approach.
                                                                                                    onSwitch(form, values, 'referredToFirm');
                                                                                                }}
                                                                                            >
                                                                                                <SyncAltIcon fontSize="small" />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </Grid>
                                                                        )}
                                                                        <Grid item={true} xs={values.isShowNotProceedingReferrer ? 12 : 12}>
                                                                            <Field name="notProceedingOutcomeNotes" subscription={{ touched: true, error: true, value: true }}>
                                                                                {({ input, meta }) => (
                                                                                    <div>
                                                                                        <TextField
                                                                                            {...input}
                                                                                            label="Not Proceeding Notes"
                                                                                            type="text"
                                                                                            className={classes.textField}
                                                                                            error={meta.error && meta.touched}
                                                                                            autoComplete="abcd"
                                                                                            disabled={values.isNotProceeding === false ? true : false}
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </Field>
                                                                        </Grid>
                                                                    </>
                                                                )}
                                                            </FormSpy>
                                                        </>
                                                    );
                                                }
                                            }}
                                        </FormSpy>
                                    </Grid>
                                    {/* {printJson(values)} */}
                                </DialogContent>
                                <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                            </form>
                        )}
                    />
                )}
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: FollowUp) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
