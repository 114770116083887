/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { Field, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
// import DialogBox from '../../Dashboard/Component/DialogBox';
import DialogBox from '../../Dashboard/Component/DialogBox';
import gql from 'graphql-tag';
import { EmailTemplateTypeEnum } from '../../emailTemplatesConfiguration/EmailTemplateRespository';
import { RvLoader } from '../../components/Loader';
// import { Editor } from '@tinymce/tinymce-react';
import { EditorWrapper } from '../../components/Editor';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../../types/DocumentFile';
import { useMutation, useQuery } from 'react-apollo';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { EntityTypeEnum } from '../selectors/UserStaffAndTeamAutoCompleteSelector';
import { AppUser, AppUserData, CreateMeetingBookingTaskMutation, fetchAppUserData } from '../EnquirySummaryRepository';
import { showNotification } from '../../App';
import { useApplicationSettings } from '../../applicationSettings/ApplicationSettingsRepository';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        dropzone: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        matterLabel: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        hideDate: {
            display: 'none'
        },
        divider: {
            width: '100%',
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        radioGroup: {
            flexFlow: 'row wrap',
            justifyContent: 'center',
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end'
        },
        dropZoneFont: {            
            minHeight: 0,
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        dropZone: {
            minHeight: 'fit-content !important' ,
            '& .MuiDropzoneArea-root' : {
                minHeight: 'fit-content !important'
            }
        },
    })
);

export enum ControlType {
    quill = 0,
    tinyMce = 1
}

interface BookMeetingEmailDialogState {
    isFormChanged: boolean;
    showDiscard: boolean;
    controlType: ControlType;
    fileSizeLimit: number;
}

export interface BookMeetingEmailDialogProps {
    followUpAction: string;
    followUpActionSystemName: string;
    isSendConfirmation: boolean;
    enquiryGuid: string;
    emailTemplateGuid?: string;
    toAddresses: string | null;
    fromAddress: string | null;
    // emailType: EmailTemplateTypeEnum;
    onClose?: () => void;
    open: boolean;
    // tslint:disable-next-line: no-any
    staff: IAutoCompleteItem | any | null;
    officeLocation: string | null;
    timezone: string | null;
    startTime: Date | null;
    endTime: Date | null;
    isAllDay: boolean;
    feeApplicable: number | null;
    meetingNotes: string | null;
}

interface BookMeetingEmailForm extends Omit<EmailDetail, 'id'> {
    files: DocumentFile[];
}

// tslint:disable-next-line: no-anyW
export const BookMeetingEmailDialog: React.FC<BookMeetingEmailDialogProps> = ( props ) => {
    const classes = useStyles();

    const applicationSettingsQuery = useApplicationSettings();
    
    const [state, setState] = useState<BookMeetingEmailDialogState>({
        showDiscard: false,
        isFormChanged: false,
        controlType: ControlType.quill,
        fileSizeLimit: 0
    });
    
    useEffect(() => {
        if (!applicationSettingsQuery.loading && !applicationSettingsQuery.error && applicationSettingsQuery.data) {
            setState((prevState) => {
                return {
                    ...prevState,
                    fileSizeLimit: applicationSettingsQuery.data?.settings.applications.systemOptions.config.emailSizeLimit || 0
                }
            });
        }
    }, [applicationSettingsQuery.data, applicationSettingsQuery.loading, applicationSettingsQuery.error]);

    const emailType = props.staff
        ? props.staff.entityType === EntityTypeEnum.TEAM
            ? EmailTemplateTypeEnum.NEW_MEETING_NOTIFICATION
            : EmailTemplateTypeEnum.MEETING_BOOKING
        : EmailTemplateTypeEnum.MEETING_BOOKING;

    const bookMettingEmailTemplateQuery = useQuery<BookEmailTemplateQueryData, BookEmailTemplateQueryParams>(BookEmailTemplateQuery, {
        variables: {
            emailTemplateGuid: props.emailTemplateGuid ?? null,
            emailType: EmailTemplateTypeEnum[emailType],
            enquiryGuid: props.enquiryGuid,
            isMeetingAllDay: props.isAllDay,
            meetingLocation: props.officeLocation ?? undefined,
            meetingEnd: props.endTime ?? undefined,
            meetingStart: props.startTime ?? undefined,
            meetingNotes: props.meetingNotes ?? undefined,
            meetingTimezone: props.timezone ?? undefined,
            meetingFeeApplicable: props.feeApplicable ?? undefined,
            isMeetingAssignedToTeam: props.staff.entityType === EntityTypeEnum.TEAM,
            meetingStaffEmail: props.staff.entityType === EntityTypeEnum.STAFF ?  props.staff.emailAddress : undefined,
            meetingStaffGuid: props.staff.value,
            meetingStaffName: props.staff.label,

        }
    });

    const [appUser, setAppUser] = useState<AppUser>();

    const fetchAppUserOnEffect = () => {
        fetchAppUserData(
            false,
            (data: AppUserData) => {
                handleAppUserFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
            },
        );
    };

    const handleAppUserFetch = (data?: AppUserData) => {
        if (data !== null && data?.appUser !== undefined && data.appUser.isAdministrator !== undefined) {
            setAppUser((prevAppUser) => {
                // Object.assign would also work
                return {
                    ...prevAppUser,
                    userId: data.appUser.userId,
                    name: data.appUser.name,
                    isAdministrator: data.appUser.isAdministrator,
                    userName: data.appUser.userName,
                };
            });
        }
    };

    const [createMeetingBooking, createMeetingBookingResult] = useMutation(CreateMeetingBookingTaskMutation);

    useEffect(() => {
        if (createMeetingBookingResult.loading) {
            showNotification(null, 'Successfully submitted', 'info'); 
            if (props.onClose) {
                props.onClose();
            }
        }
        if (createMeetingBookingResult.error) {
            showNotification('Failed to send email', createMeetingBookingResult.error.message, 'error'); 
        }
        fetchAppUserOnEffect();
    },        [createMeetingBookingResult.called, createMeetingBookingResult.loading, createMeetingBookingResult.data, createMeetingBookingResult.error]);

    const onSubmit = (values: BookMeetingEmailForm) => {
        // code here

        createMeetingBooking({
            variables: {
                input: {
                    enquiryGuid: props.enquiryGuid,
                    assignedTo: props.staff.value,
                    followUpAction: props.followUpAction,
                    followUpActionSystemName: props.followUpActionSystemName,
                    startDate: props.startTime,
                    endDate: props.endTime,
                    isAllDay: props.isAllDay,
                    location: props.officeLocation ? props.officeLocation : null,
                    feeApplicable: props.feeApplicable ? props.feeApplicable : null,
                    sendConfirmation: props.isSendConfirmation,
                    staff: props.staff ? props.staff!.value : null,
                    staffEntityType: props.staff ? props.staff.entityType : null,
                    meetingNotes: props.meetingNotes ? props.meetingNotes : null,
                    timezone: props.timezone ? props.timezone : null,
                    emailContent: values.emailContent,
                    emailSubject: values.emailSubject,
                    fromEmailAddress: values.fromAddress,
                    toEmailAddresses: values.toAddresses,
                    ccEmailAddresses: values.ccAddresses,
                    files: GetFiles(values.files)
                }
            }
        });
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status
            };
        });
    };

    const onFollowUpClosed = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<any>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isFormChanged', true);
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            onFollowUpClosed();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });

            onFollowUpClosed();
            
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const displayLoader = () => {
        return (
            <Dialog
                open={props.open}
                onClose={() => onClose()}
                className={classes.root}
                fullWidth={true}
                maxWidth="md"
            >
                <DialogTitle
                    id="form-dialog-title"
                    className={classes.dialogTitle}
                >
                    Book Meeting Email
                </DialogTitle>
                <DialogContent dividers={true}>                                            
                    <Grid container={true} spacing={1}>
                        <Grid item={true} sm={12} md={12} lg={12} xs={12} justify="center" alignItems="center">
                            <RvLoader />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

        );
    };

    // tslint:disable-next-line: no-any
    const EmailValidateWithRequiredNew = (value: any) => {
        let error: string = '';

        if (value === undefined || value === null) {
            return value ? '' : 'Email is required';
        } else if (value.length === 0) {
            return value ? '' : 'Email is required'; 
        } else {

            const emails = value.split(';').map((email: string) => email.trim());

            for (let i = 0; i < emails.length; i++) {
                const email = emails[i];
                const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
            
                if (!isValid) {
                    error = 'Email is not valid';
                }
            }
        }
        return error;
    };

    // tslint:disable-next-line: no-any
    const EmailValidate = (value: any) => {
        let error: string = '';

        if (value !== undefined && value !== null) {
            const emails = value.split(';').map((email: string) => email.trim());

            for (let i = 0; i < emails.length; i++) {
                const email: string = emails[i];

                if (email.length > 0) {
                    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
                    if (!isValid) {
                        error = 'Email is not valid';
                    }
                }
            }
        }

        return error;
    };

    return (
        <React.Fragment>
            {
                bookMettingEmailTemplateQuery.loading || applicationSettingsQuery.loading
                    ? displayLoader()
                    : (
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                emailContent: bookMettingEmailTemplateQuery.data?.templates.emailDetail.emailContent,
                                emailSubject: bookMettingEmailTemplateQuery.data?.templates.emailDetail.emailSubject,
                                fromAddress: bookMettingEmailTemplateQuery.data?.templates.emailDetail.fromAddress ?? appUser ? appUser?.userName : null,
                                toAddresses: bookMettingEmailTemplateQuery.data?.templates.emailDetail.toAddresses ?? null,
                                ccAddresses: bookMettingEmailTemplateQuery.data?.templates.emailDetail.ccAddresses ?? null,
                                emailType: bookMettingEmailTemplateQuery.data?.templates.emailDetail.emailType,
                                emailStyle: bookMettingEmailTemplateQuery.data?.templates.emailDetail.emailStyle,
                                files: []
                            }}
                            initialValuesEqual={() => true}
                            subscription={{submitting: true, pristine: true}}
                            validate={(values: BookMeetingEmailForm) => {
                                let errors = {
                                    fromAddress: '',
                                    toAddresses: '',
                                    ccAddresses: ''
                                };

                                if ((!values.fromAddress) 
                                    && (!values.toAddresses)) {
                                    errors.fromAddress = 'From email address is required';
                                    errors.toAddresses = 'To email address is required';
                                } else if (values
                                    && (values.fromAddress && values.fromAddress.length === 0)
                                    && (values.toAddresses && values.toAddresses.length === 0)) {
                                    errors.fromAddress = 'From email address is required';
                                    errors.toAddresses = 'To email address is required';
                                } else if (
                                        (values.fromAddress !== undefined && values.fromAddress !== null 
                                            && values.fromAddress.length > 0) 
                                        || (values.toAddresses !== undefined && values.toAddresses !== null 
                                            && values.toAddresses.length > 0) 
                                        || (values.ccAddresses !== undefined && values.ccAddresses !== null 
                                            && values.ccAddresses.length > 0) 
                                    ) {
                                    errors.fromAddress = EmailValidateWithRequiredNew(values.fromAddress);
                                    errors.toAddresses = EmailValidateWithRequiredNew(values.toAddresses);
                                    errors.ccAddresses = EmailValidate(values.ccAddresses);
                                }
                                    
                                if (errors.fromAddress === '' && errors.toAddresses === '' && errors.ccAddresses === '') {
                                    return undefined;
                                }

                                return errors;
                            }}
                            render={({handleSubmit, form, submitting, pristine, values}) => (
                                <form onSubmit={event => handleSubmit(event)} id="sendEmailDialog" autoComplete="off">
                                    {displayLoader()}
                                    <FormSpy 
                                        subscription={{ pristine: true, values: true }}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={props => {
                                            onFormValueChanged(form, props);
                                        }}
                                    />                        
                                    <DialogBox
                                        title="Book Meeting Email"
                                        // tslint:disable-next-line:max-line-length
                                        content={`Are you sure you want to close the form?`}
                                        show={state.showDiscard ? state.showDiscard : false}
                                        isAgree={onDiscardChanges}
                                        disAgreeLabel={'No'}
                                        agreeLabel={'Yes'}
                                    />
                                    
                                    <Dialog
                                        open={props.open}
                                        onClose={() => onClose()}
                                        className={classes.root}
                                        fullWidth={true}
                                        maxWidth="md"
                                        disableEnforceFocus={true}
                                    >
                                        <DialogTitle
                                            id="form-dialog-title"
                                            className={classes.dialogTitle}
                                        >
                                            Book Meeting Email
                                        </DialogTitle>
                                        <DialogContent dividers={true}>
                                            <Grid container={true} spacing={1}>
                                                <FormSpy subscription={{ values: true, validating: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="fromAddress"
                                                                    type="email"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="From"
                                                                                type="email"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="toAddresses"
                                                                    // type="email"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="To"
                                                                                // type="email"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="ccAddresses"
                                                                    // type="email"
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="Cc"
                                                                                // type="email"
                                                                                required={undefined}
                                                                                className={classes.textField}
                                                                                error={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                }
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="emailSubject"
                                                                    type="text"
                                                                    validate={required}
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="Subject"
                                                                                type="text"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                }
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="emailContent"
                                                                    type="text"
                                                                    validate={required}
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <EditorWrapper
                                                                            value={input.value}
                                                                            onEditorChange={(content: string) => {
                                                                                input.onChange(content);
                                                                                // setState((prevState) => {
                                                                                //     return {
                                                                                //         ...prevState,
                                                                                //         emailContent: content
                                                                                //     };
                                                                                // });
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                {/* )} */}
                                                            </Grid>
                                                            <Grid
                                                                item={true}
                                                                xs={12}
                                                                md={12}
                                                            >
                                                                <Field
                                                                    name="files"
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <DropzoneArea
                                                                            {...input}
                                                                            inputProps={{
                                                                                className: classes.dropZone
                                                                            }}
                                                                            // key={filesKey}
                                                                            maxFileSize={state.fileSizeLimit * 1_048_576} 
                                                                            // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                                                            showFileNames={true}
                                                                            useChipsForPreview={true}
                                                                            filesLimit={10}
                                                                            dropzoneText="To add a file, drag and drop or click in this box.
                                                                            Click the 'X' on a file to delete.
                                                                            Click the filename to replace it.
                                                                            View a file using the links above. 
                                                                            These links will become available once the enquiry is saved."
                                                                            dropzoneClass={classes.dropZoneFont}
                                                                            // tslint:disable-next-line: no-shadowed-variable
                                                                            onChange={(files: File[]) => {
                                                                                let existingFiles: DocumentFile[] = [...input.value];
                                                                                const newFiles = files.filter((file: File) => {
                                                                                    return !existingFiles.some((item: DocumentFile) => {
                                                                                        return item.name === file.name && item.status !== 2;
                                                                                    });
                                                                                });
                                                                                if (newFiles && newFiles.length > 0) {
                                                                                    newFiles.forEach((file: File) => {
                                                                                        const documentFile: DocumentFile = {
                                                                                            guidID: null,
                                                                                            file: file,
                                                                                            status: 1,
                                                                                            name: file.name,
                                                                                            url: ''
                                                                                        };
                                                                                        existingFiles.push(documentFile);
                                                                                    });  
                                                                                    input.onChange(existingFiles);
                                                                                }
                                                                            }}
                                                                            onDelete={(file: File) => {
                                                                                let existingFiles: DocumentFile[] = [...input.value];
                                                                                // eslint-disable-next-line array-callback-return
                                                                                existingFiles.map((item: DocumentFile) => {
                                                                                    if (file.name === item.name) {
                                                                                        item.file = file;
                                                                                        item.status = 2; // deleted
                                                                                    }
                                                                                });  
                                                                                // tslint:disable-next-line: max-line-length
                                                                                const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                                                    return item.status !== 2 || item.guidID !== null;
                                                                                });
                                                                                input.onChange(filteredFiles);
                                                                            }}                                                    
                                                                            // tslint:disable-next-line
                                                                            onDrop={(files: File[], event: any) => {
                                                                                // let existingFiles: DocumentFile[] = [...input.value];
                                                                                // tslint:disable-next-line: max-line-length
                                                                                // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                                                                // tslint:disable-next-line:no-console
                                                                                console.log(event);
                                                                            }}                                                  
                                                                            initialFiles={input.value && input.value.map((file: { url: string; }) => file.url)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </FormSpy>
                                            </Grid>
                                            {/* {printJson(values)} */}
                                        </DialogContent>
                                        <DialogActions>
                                            <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values, pristine }) => (
                                                    <>
                                                        <Button 
                                                            // tslint:disable-next-line: max-line-length
                                                            disabled={submitting} 
                                                            color="primary"
                                                            type="submit"
                                                            onClick={() => form.submit()} 
                                                        >
                                                            Send
                                                        </Button>
                                                        
                                                        <Button 
                                                            onClick={() => onClose()} 
                                                            color="primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </>
                                                )}
                                            </FormSpy>                                
                                        </DialogActions>
                                    </Dialog>
                                </form>
                            )}
                        />
                    )
                }
        </React.Fragment>
    );
};

interface BookEmailTemplateQueryParams {
    emailType?: string;
    emailTemplateGuid: string | null;
    enquiryGuid?: string;
    isMeetingAssignedToTeam?: boolean;
    meetingStaffGuid?: string;
    meetingStaffName?: string;
    meetingStaffEmail?: string;
    meetingLocation?: string;
    meetingTimezone?: string;
    meetingStart?: Date;
    meetingEnd?: Date;
    isMeetingAllDay?: boolean;
    meetingNotes?: string;
    meetingFeeApplicable?: number;
}

export interface BookEmailTemplateQueryData {
    templates: Templates;
}

export interface Templates {
    emailDetail: EmailDetail;
}

export interface EmailDetail {
    id:           number;
    fromAddress:  string | null;
    toAddresses:  string | null;
    ccAddresses:  string | null;
    emailType:    number;
    emailStyle:   number;
    emailSubject: string | null;
    emailContent: string | null;
}

const BookEmailTemplateQuery = gql`
    query templateWithEmailBodyForEnquiry(
        $emailType: EmailType, 
        $emailTemplateGuid: String, 
        $enquiryGuid: String,
        $isMeetingAssignedToTeam: Boolean,
        $meetingStaffGuid: String,
        $meetingStaffName: String,
        $meetingStaffEmail: String,
        $meetingLocation: String,
        $meetingTimezone: String,
        $meetingStart: DateTime,
        $meetingEnd: DateTime,
        $isMeetingAllDay: Boolean,
        $meetingNotes: String,
        $meetingFeeApplicable: Decimal
    ) {
        templates {
            emailDetail(
                emailType: $emailType,
                emailTemplateGuid: $emailTemplateGuid, 
                enquiryGuid: $enquiryGuid,
                isMeetingAssignedToTeam: $isMeetingAssignedToTeam,
                meetingStaffGuid: $meetingStaffGuid,
                meetingStaffName: $meetingStaffName,
                meetingStaffEmail: $meetingStaffEmail,
                meetingLocation: $meetingLocation,
                meetingTimezone: $meetingTimezone,
                meetingStart: $meetingStart,
                meetingEnd: $meetingEnd,
                isMeetingAllDay: $isMeetingAllDay,
                meetingNotes: $meetingNotes,
                meetingFeeApplicable: $meetingFeeApplicable
            ) {
                id
                fromAddress
                toAddresses
                ccAddresses
                emailType
                emailStyle
                emailSubject
                emailContent
            }
        }
    }
`;