/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { mainTheme } from '../../Theme';
import { Form, Field, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { 
    CompleteCreateClientMutation,
    EnquiryDetailsQueryParams,
    retrieveEnquiryDetailsData,
} from '../EnquirySummaryRepository';
import { RvLoader } from '../../components/Loader';
import { EnquiryData } from '../models/Enquiry';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { showNotification } from '../../App';
import { client } from '../..';
// import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import { useHistory } from 'react-router-dom';
import { MatterAutoCompleteSelector } from '../../components/MatterAutoCompleteSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {

        },
        textField: {
            width: '100%',
            flex: 1,
            maxWidth: 'auto'
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
            '& .MuiTypography-root': {
                display: 'flex',
                margin: '0 -10px',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& .col': {
                    padding: '0 10px',
                    fontSize: '12px',
                    color: '#000',
                }
            }
        },
        divider: {
            width: '100%',
        },
        customFieldGrid: {
            // paddingTop: '0px !important',
        },
        matterSelectorWrapper: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            width: '100%',
        }
    })
);

export interface MarkClientAndMatterCreated {
    // tslint:disable-next-line: no-any
    matter: IAutoCompleteItem | null | any;
}

interface MarkClientAndMatterCreatedDialogProps {
    guid?: string;
    onDialogClose?: () => void;
    canCreateMatter?: boolean;
}

interface MarkClientAndMatterCreatedDialogState {
    isFormChanged: boolean;
    showDiscard: boolean;
}

// tslint:disable-next-line: no-anyW
export const MarkClientAndMatterCreatedDialog: React.FunctionComponent<MarkClientAndMatterCreatedDialogProps> = props => {
    const classes = useStyles();
    // tslint:disable-next-line: no-any
    // const { isFollowUpOpen, onFollowUpClose }: any = useContext(EnquirySummaryContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const history = useHistory();   

    const [formState, setFormState] = useState<MarkClientAndMatterCreated>({
        matter: null
    });

    const [state, setState] = useState<MarkClientAndMatterCreatedDialogState>({
        showDiscard: false,
        isFormChanged: false,
    });

    useEffect(() => {
        // - Ran only once
        // If we recieve a guid, then fetch EnquiryDetails
        if (props.guid) {
            fetchEnquiryDetails(props.guid);
        }
    // tslint:disable-next-line: align
    }, [props.guid]);  

    const displayLoader = () => {
        if (isLoading) {
            return <RvLoader />;
        } else {
            return null;
        }
    };

    const fetchEnquiryDetails = (guid: string) => {
        var enquiryQueryParams: EnquiryDetailsQueryParams = {
            guid: guid,
        };

        retrieveEnquiryDetailsData(
            enquiryQueryParams,
            true,
            (data) => onDataRetrieved(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const onDataRetrieved = (data: EnquiryData) => {
        setFormState((prevState) => {
            const tempMatter = data.enquiry.detail;

            return {
                ...prevState,
                matter: tempMatter.matterId ? {
                    label: tempMatter.matterFileNumber + ' (' + tempMatter.matterTitle + ')',
                    value: '' + tempMatter.matterGuid,                       
                    fileNumber: tempMatter.matterFileNumber,
                    title: tempMatter.matterTitle,
                } : null
            };
        });

        setIsLoading(false);

    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any
    ) => {
        return (
            <div>
                <Button
                    type="submit" 
                    color="primary"
                    disabled={submitting 
                        || isSubmitting
                    }
                >
                    Save
                </Button>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
            </div>
        );
    };

    const onSubmit = (values: MarkClientAndMatterCreated) => {
        setIsSubmitting(true);
        client.mutate({
            mutation: CompleteCreateClientMutation,
            variables: { 
                input: {
                    enquiryGuid: props.guid,
                    matterGuid: values.matter ? values.matter.value : null
                }
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info');
                    onDialogClose();
                } else {
                    showNotification('Complete Create Client Failed', results.data.error, 'error');
                }
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Complete Create Client Failed', reason, 'error');
        });
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<any>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isFormChanged', true);
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            onDialogClose();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            onDialogClose();
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });
        }
    };

    const onDialogClose = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    // tslint:disable-next-line: no-any
    // const onShowMatterDetails = (values: any) => () => {

    //     sessionStorage.setItem('typeOfView', 'noback');
        
    //     const tempMatter = values.matter;

    //     //  code here...
    //     var href = history.createHref({
    //         pathname: '/matterDetail/' + tempMatter.id,
    //         key: 'matterManagement',
    //         state: {
    //             matterManagementState: 'false',
    //         }
    //     });

    //     window.open(href, '_blank');
    // };

    return (
        <>
            <DialogBox
                title="Mark Client/Matter Created"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={state.showDiscard ? state.showDiscard : false}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />
            <Dialog
                open={true}
                onClose={onClose}
                aria-labelledby="form-dialog-title"
                className={classes.root}
                fullWidth={true}
                maxWidth="xs"
                scroll="paper"
            >
                {isLoading 
                    ? displayLoader()
                    : (
                        <Form
                            onSubmit={onSubmit}
                            initialValues={formState}
                            initialValuesEqual={() => true}
                            // keepDirtyOnReinitialize={true}
                            subscription={{ submitting: true, pristine: true, values: true }}
                            render={({handleSubmit, form, submitting, pristine, values
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    {displayLoader()}
                                    <FormSpy 
                                        subscription={{ pristine: true }}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={props => {
                                            onFormValueChanged(form, props);
                                        }}
                                    />
                                    <DialogTitle id="form-dialog-title" className={classes.dialogTitle} >                                
                                        <div>{`Mark as ${props.canCreateMatter ? 'Matter' : 'Client'} Created`}</div>
                                    </DialogTitle>
                                    <DialogContent dividers={true}>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} xs={12} md={12}>
                                                <FormSpy subscription={{ values: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <div className={classes.matterSelectorWrapper}>
                                                            <Field
                                                                name="matter"
                                                                // tslint:disable-next-line: max-line-length
                                                                subscription={{ touched: true, error: true, value: true }}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <MatterAutoCompleteSelector
                                                                        {...input}
                                                                        label="Matter"
                                                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                                            input.onChange(selection);
                                                                        }}
                                                                        value={input.value ? input.value : null}
                                                                        error={meta.error && meta.touched}
                                                                        helperText={
                                                                            meta.error &&
                                                                            meta.touched
                                                                                ? meta.error
                                                                                : 'Select Matter if applicable otherwise save'
                                                                        }
                                                                        className={classes.textField}
                                                                        rootClassName={classes.textField}
                                                                        disablePortal={false}
                                                                        textLimit={{
                                                                            description: 50,
                                                                            title: 50
                                                                        }}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {/* <IconButton 
                                                                size="small"
                                                                onClick={onShowMatterDetails(values)}
                                                                disabled={!values.matter}
                                                            >
                                                                <OpenInNewIcon />
                                                            </IconButton> */}
                                                        </div>
                                                    )}
                                                </FormSpy>
                                            </Grid>
                                        </Grid>
                                    {/* {printJson(values)} */}
                                    </DialogContent>
                                    <DialogActions>
                                        {actionButton(form, submitting, pristine, values)}
                                    </DialogActions>
                                </form>
                            )}
                        />
                    )
                }
            </Dialog>
        </>
    );
};

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}