import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
// tslint:disable-next-line: max-line-length
import { Dialog, DialogContent, Button, DialogActions, Typography, IconButton, Stepper, Step, StepLabel, RadioGroup, FormControl, FormControlLabel, Radio, TextField, CircularProgress } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { mainTheme } from '../../Theme';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { EnquiryAutocompleteSelector } from '../selectors/EnquiryAutocompleteSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { showNotification } from '../../App';
import { client } from '../..';
// import { client } from '../..';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        heading: {
            color: mainTheme.BrandColors.TemplatePrimary,
            flex: 1,
            textAlign: 'center'
        },
        loadingWrapper: {

        },
        button: {},
        stepContent: {
            padding: '20px 0'
        },
        stepInnerContent: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center'
        },
        contentDescription: {
            textAlign: 'center',
            marginBottom: '10px'
        },
        waringDescription: {
            textAlign: 'center',
            color: 'red',
            marginBottom: '10px'
        },
        enquiryName: {
            textAlign: 'center',
            marginBottom: '10px',
            fontWeight: 700
        },
        finishIcon: {
            marginBottom: '30px',
            width: 75,
            height: 75,
        },
        formControlLabel: {
            marginBottom: '10px'
        },
        enquirySelector: {
            minWidth: '350px'
        },
        scrubDescription: {
            fontSize: '32px',
            fontWeight: 600,
            marginBottom: '20px'
        }
    })
);

interface ScrubEnquiryDataDialogProps {
    open: boolean;
    onClose?: () => void;
}

export enum SrcubOptionsEnum {
    longerThanSixMonth = 0,
    selectFromEnquiry
}

interface ScrubEnquiryDataDialogState {
    options: SrcubOptionsEnum;
    isScrubSubmitLoading: boolean;
    selectedEnquiries: IAutoCompleteItem[] | null;
    isComplete: boolean;
    message: string;
    enquiriesScrubbed: number;
    totalEnquiries: number;
}

export const ScrubEnquiryDataDialog: React.FC<ScrubEnquiryDataDialogProps> = props => {
    
    const classes = useStyles();

    const steps = ['Scrub Options', 'Selected Scrub', 'Finished'];

    const [activeStep, setActiveStep] = React.useState(0);
    const [scrubData, setScrubData] = React.useState('');

    const [state, setState] = React.useState<ScrubEnquiryDataDialogState>({
        options: SrcubOptionsEnum.longerThanSixMonth,
        isScrubSubmitLoading: false,
        selectedEnquiries: [],
        isComplete: false,
        message: 'Enquiry Scrubbing In Progress',
        enquiriesScrubbed: 0,
        totalEnquiries: 0
    });

    const [scrubEnquiryDataMutation] = useMutation<ScrubEnquiryMutationData>(SCRUB_ENQUIRY_DATA_MUTATION);

    // tslint:disable-next-line: max-line-length
    const [scrubEnquiryDataByEnquiryMutation] = useMutation<ScrubEnquiryByEnquiryMutationData, ScrubEnquiryByEnquiryMutationParams>(SCRUB_ENQUIRY_DATA_BY_ENQUIRY_MUTATION);

    const enquiryScrubbingInProgressNotificationSubscription = () => {
        return (
            // let subscription =
            client.subscribe({
                fetchPolicy: 'no-cache',
                query: ENQUIRY_SCRUBBING_INPROGRESS_NOTIFICATION,
                variables: {},
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {

                    const tempNotification = results.data.enquiryScrubbingInProgressNotification;

                    // showNotification('Success', tempNotification.message, 'info');

                    setState((prevState) => {
                        return {
                            ...prevState,
                            message: tempNotification.message,
                            enquiriesScrubbed: tempNotification.enquiriesScrubbed,
                            totalEnquiries: tempNotification.totalEnquiries,
                        };
                    });
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                  // tslint:disable-next-line:no-console
                  console.error(err);
                  showNotification(null, err, 'error');
                },
            })
        );
    };

    const enquiryScrubbingCompleteNotificationSubscription = () => {
        return (
            // let subscription =
            client.subscribe({
                fetchPolicy: 'no-cache',
                query: ENQUIRY_SCRUBBING_COMPLETE_NOTIFICATION,
                variables: {},
            })
            .subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {

                    const tempNotification = results.data.enquiryScrubbingCompleteNotification;

                    if (results.data.enquiryScrubbingCompleteNotification.isComplete) {
                        showNotification('Success', tempNotification.message, 'info');
                    } else {
                        showNotification('Failed', tempNotification.message, 'error');
                    }
                    setState((prevState) => {
                        return {
                            ...prevState,
                            message: tempNotification.message,
                            isComplete: tempNotification.isComplete,
                            isScrubSubmitLoading: false,
                            selectedEnquiries: []
                        };
                    });

                    setScrubData('');
                    
                    // indicate that the scrubbing is complete
                    setActiveStep(2);
                },
                // tslint:disable-next-line:no-any
                error(err: any) {
                  // tslint:disable-next-line:no-console
                  console.error(err);
                  showNotification(null, err, 'error');
                },
            })
        );
    };

    React.useEffect(() => {
        const enquiryScrubbingInProgressNotificationSubscriptionObj = enquiryScrubbingInProgressNotificationSubscription();
        const enquiryScrubbingCompleteNotificationSubscriptionObj = enquiryScrubbingCompleteNotificationSubscription();
        return () => {
          enquiryScrubbingInProgressNotificationSubscriptionObj.unsubscribe();
          enquiryScrubbingCompleteNotificationSubscriptionObj.unsubscribe();
        };
      // tslint:disable-next-line: align
    }, []);
    
    const onClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleConfirm = () => {

        setState((prevState) => {
            return {
                ...prevState,
                isScrubSubmitLoading: true
            };
        });

        if (state.options === SrcubOptionsEnum.longerThanSixMonth) {
            scrubEnquiryDataMutation()
            .then((result: { data: ScrubEnquiryMutationData}) => {
                if (result.data.scrubEnquiryData.status) {
                    showNotification(null, 'Scrub enqury successfully submitted.', 'info');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            })
            // tslint:disable-next-line: no-any
            .catch((reason: any) => {
                showNotification(null, reason, 'error');
            });
        } else {
            scrubEnquiryDataByEnquiryMutation({
                variables: {
                    // tslint:disable-next-line: max-line-length
                    guids: state.selectedEnquiries && state.selectedEnquiries.length > 0 ? state.selectedEnquiries.map((selectedEnquiry: IAutoCompleteItem) => selectedEnquiry.value).join(',') : ''
                }
            })
            .then((result: { data: ScrubEnquiryByEnquiryMutationData}) => {
                if (result.data.scrubEnquiryDataByEnquiry.status) {
                    showNotification(null, 'Scrub enqury successfully submitted.', 'info');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            })
            // tslint:disable-next-line: no-any
            .catch(function (reason: any) {
                showNotification(null, reason, 'error');
            });
        }
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleScrubMore = () => {
        setActiveStep(0);
        setScrubData('');
        setState((prevState) => {
            return {
                ...prevState,
                isScrubSubmitLoading: false,
                selectedEnquiries: [],
                isComplete: false,
                message: 'Enquiry Scrubbing In Progress',
                enquiriesScrubbed: 0,
                totalEnquiries: 0
            };
        });
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setState((prevSate) => {
            return {
                ...prevSate,
                // tslint:disable-next-line: no-any
                options: SrcubOptionsEnum[SrcubOptionsEnum[(event.target as HTMLInputElement).value as any]]
            };
        });
    };

    const onChangeTextField = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setScrubData(event.target.value);
    };

    const onSelectEnquiry = (value: IAutoCompleteItem[], name: string) => {
        setState((prevState) => {
            return {
                ...prevState,
                selectedEnquiries: value,
                totalEnquiries: value.length
            };
        });
    };

    const scrubOptionsSteps = () => {
        return (
            <div className={classes.stepInnerContent}>
                <Typography
                    className={classes.contentDescription}
                >
                    This function with remove personal data relating to new enquiries
                </Typography>
                <FormControl component="div">
                    <RadioGroup name="options" value={state.options} onChange={handleChange}>
                        <FormControlLabel 
                            value={SrcubOptionsEnum.longerThanSixMonth} 
                            control={<Radio color="primary" />}
                            label="That have been added to the database longer than 6 months, are no longer active, have not become a client and have not provided consent for marketing" 
                            className={classes.formControlLabel}
                        />
                        <FormControlLabel 
                            value={SrcubOptionsEnum.selectFromEnquiry} 
                            control={<Radio color="primary" />} 
                            label={
                                <EnquiryAutocompleteSelector 
                                    name="enquiry"
                                    label="Select Enquiry"
                                    variant="outlined"
                                    value={state.selectedEnquiries}
                                    onSelection={onSelectEnquiry}
                                    disabled={state.options === SrcubOptionsEnum.longerThanSixMonth}
                                    multiple={true}
                                    className={classes.enquirySelector}
                                    disableCloseOnSelect={true}
                                />
                            } 
                            className={classes.formControlLabel}
                        />
                    </RadioGroup>
                </FormControl>
            </div>
        );
    };

    const scrubSelectedSteps = () => {

        if (state.options === SrcubOptionsEnum.longerThanSixMonth) {
            return (
                <div className={classes.stepInnerContent}>
                    <Typography
                        className={classes.contentDescription}
                    >
                        That have been added to the database longer than 6 months, are no longer active, have not become a client and have not provided consent for marketing
                    </Typography>
                    <Typography
                        className={classes.waringDescription}
                    >
                        Continuing will remove data from the system - it is NOT REVERSIBLE
                    </Typography>
                    <Typography
                        className={classes.contentDescription}
                    >
                        To proceed enter SCRUB DATA in the text box below and select OK
                    </Typography>
                    <TextField 
                        name="scrubData"
                        value={scrubData}
                        variant="outlined"
                        onChange={onChangeTextField('scrubData')}
                    />
                </div>
            );
        }
        return (
            <div className={classes.stepInnerContent}>
                <Typography
                    className={classes.contentDescription}
                >
                    This function with remove personal data relating to new enquiry
                </Typography>
                <Typography
                    className={classes.enquiryName}
                >
                    {state.selectedEnquiries ? state.selectedEnquiries.map((enquiry: IAutoCompleteItem) => enquiry.label).join(', ') : ''}
                </Typography>
                <Typography
                    className={classes.waringDescription}
                >
                    Continuing will remove data from the system - it is NOT REVERSIBLE
                </Typography>
                <Typography
                    className={classes.contentDescription}
                >
                    To proceed enter SCRUB DATA in the text box below and select OK
                </Typography>
                <TextField 
                    name="scrubData"
                    value={scrubData}
                    variant="outlined"
                    onChange={onChangeTextField('scrubData')}
                />
            </div>
        );
    };

    const finishSteps = () => {

        if (state.isScrubSubmitLoading) {
            return (
                <div className={classes.stepInnerContent}>
                    <CircularProgress 
                        className={classes.finishIcon}
                        color="primary"
                    />
                    {state.totalEnquiries !== 0 && (
                        <Typography className={classes.scrubDescription}>
                            {`${state.enquiriesScrubbed}/${state.totalEnquiries}`}
                        </Typography>
                    )}
                    <Typography className={classes.contentDescription}>
                        {state.message}
                    </Typography>
                </div>
            );
        }

        return (
            <div className={classes.stepInnerContent}>
                    <CheckCircleIcon 
                        className={classes.finishIcon}
                        color="primary"
                    />
                    <Typography className={classes.contentDescription}>
                        {state.message}
                    </Typography>
                </div>
        );
    };

    const stepsContent = () => {
        switch (activeStep) {
            case 0: 
                return scrubOptionsSteps();
            case 1:
                return scrubSelectedSteps();
            case 2:
                return finishSteps();
            default:
                return scrubOptionsSteps();
        }
    };
    
    return (
        <Dialog 
            onClose={onClose} 
            open={props.open}
            fullWidth={true}
            maxWidth="sm"
        >
            <MuiDialogTitle disableTypography={true} >
                <Typography variant="h6" className={classes.heading}>SCRUB ENQUIRY DATA</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </MuiDialogTitle>
            <DialogContent dividers={true}>
                <Stepper activeStep={activeStep}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div className={classes.stepContent}>
                    {stepsContent()}
                </div>
            </DialogContent>
            <DialogActions>
                {activeStep !== 2 && (
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                        Back
                    </Button>
                )}
                {activeStep === 2 && (
                    <Button 
                        onClick={handleScrubMore} 
                        className={classes.button}
                        disabled={state.isScrubSubmitLoading}
                    >
                        Scrub More
                    </Button>
                )}
                {activeStep === 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                    >
                        Next
                    </Button>
                )} 
                {activeStep === 1 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirm}
                        className={classes.button}
                        disabled={scrubData !== 'SCRUB DATA'}
                    >
                        Confirm
                    </Button>
                )}
                {activeStep === 2 && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={props.onClose}
                        className={classes.button}
                        disabled={state.isScrubSubmitLoading}
                    >
                        Close
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export interface ScrubEnquiryMutationData {
    scrubEnquiryData: ScrubEnquiryData;
}

export interface ScrubEnquiryData {
    status: boolean;
}

export const SCRUB_ENQUIRY_DATA_MUTATION = gql`
    mutation ScrubEnquiryDataMutation{
        scrubEnquiryData{
            status
        }
    }
`;

export interface ScrubEnquiryByEnquiryMutationParams {
    guids: string;
}

export interface ScrubEnquiryByEnquiryMutationData {
    scrubEnquiryDataByEnquiry: ScrubEnquiryData;
}

export interface ScrubEnquiryData {
    status: boolean;
}

export const SCRUB_ENQUIRY_DATA_BY_ENQUIRY_MUTATION = gql`
    mutation ScrubEnquiryDataByEnquiryMutation($guids: String) {
        scrubEnquiryDataByEnquiry(enquiryGuids: $guids) {
            status
        }
    }
`;

export const ENQUIRY_SCRUBBING_INPROGRESS_NOTIFICATION = gql`
    subscription {
        enquiryScrubbingInProgressNotification {
            description
            enquiriesScrubbed
            id
            message
            status
            totalEnquiries
        }
    }
`;

export const ENQUIRY_SCRUBBING_COMPLETE_NOTIFICATION = gql`
    subscription {
        enquiryScrubbingCompleteNotification {
            description
            id
            isComplete
            message
            status
        }
    }
`;